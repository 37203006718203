$('body').on('keypress', 'input[type=text][data-money]', function(e){
    var k = e.which;

    // Allowed keycodes
    var key_codes = [44, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 0, 8, 9, 13];

    // No second comma can be inserted
    if($.inArray(k,[44, 46]) !== -1 && $(this).val().indexOf(',') !== -1){
        e.preventDefault();
        return;
    }

    // Replace Dot with Comma
    if(k == 46){
        var position = $(this).getCursorPosition();
        var currentValue = $(this).val();
        var newValue = currentValue.substr(0, position) + "," + currentValue.substr(position);
        $(this).val(newValue);
        $(this).setCursorPosition(position+1);
    }

    if ($.inArray(k,key_codes) < 0){
        e.preventDefault();
    }
});

// Max two after comma
$('body').on('keyup', 'input[type=text][data-money]', function(){
    var position = $(this).getCursorPosition();
    if ($(this).val().indexOf(',') !== -1) {
        var value = $(this).val().split(',');

        if(value[1].length > 2){
            $(this).val(value[0] + ',' + value[1].substr(0, 2));
            $(this).setCursorPosition(position);
        }
    }
});