if ($('[data-facade]').length > 0){
    $('[data-front-facade]').click(function(){
        setFacadeType();
    });
    function setFacadeType()
    {
        if ($('[data-front-facade]:checked').data('front-facade') == 1) {
            $('[data-facade-type]').html('Voor');
        } else{
            $('[data-facade-type]').html('Zij');
        }
    }
    setFacadeType();

    $('[data-facade-on-type]').click(function(){
        setFacadeOnType()
    });
    function setFacadeOnType()
    {
        if ($('[data-facade-on-type]:checked').data('facade-on-type') == 'wall') {
            $('#facade_type_wall').removeClass('hide')
        } else{
            $('#facade_type_wall').addClass('hide')
        }
    }
    setFacadeOnType();


    var rows = {
        'facade_lattices': 0,
        'oh_doors': 0,
        'doors': 0,
        'windows': 0,
        'sliding_doors': 0
    };
    
    var ralSelect = false;
    function setRalOptions()
    {
        ralSelect = '<option value="">Niet gecoat</option>' +
        '<option value="0">Gecoat, kleur niet bepaald</option>';
        for(var i in ral_colors){
            ralSelect += '<option value="' + ral_colors[i] + '">RAL' + ral_colors[i] + '</option>';
        }
    }

    function getRalSelect()
    {
        if (! ralSelect){
            setRalOptions();
        }

        return '<select data-color>' + ralSelect + '</select>';
    }
    
    function colorHeightWidthRow(type)
    {
        console.log(type);
        return '' +
        '<div data-' + type + ' class="columns ' + type + '">' +
            '<div class="row">' +
                '<div class="input-holder-facade">' +
                    '<div class="small-3 medium-3 columns">' +
                        getRalSelect() +
                    '</div>' +
    
                    '<div class="small-3 medium-3 columns">' +
                        '<input data-' + ((type == 'door' || type == 'facade-lattice') ? 'width' : 'height' ) + ' data-meters type="text">' +
                    '</div>' +
                    '<div class="small-3 medium-3 columns">' +
                        '<input data-' + ((type == 'door' || type == 'facade-lattice') ? 'height' : 'width' ) + ' data-meters type="text">' +
                    '</div>' +
    
                    '<div class="small-2 medium-1 icon-holder end columns">' +
                        '<a data-remove-color-height-width="' + type + '"><i class="fi-x"></i></a>' +
                    '</div>' +
                '</div>' +
            '</div>' +
        '</div>';
    }
    $('[data-add-color-height-width]').click(function(){
        addColorHeightWidthRow($(this).data('add-color-height-width'));
    });
    $('[data-color-height-width-container]').on('click', '[data-remove-color-height-width]', function(){
        removeColorHeightWidthRow($(this));
    });
    function addColorHeightWidthRow(type)
    {
        var html = colorHeightWidthRow(type);
        var plural = getPlural(type);

        $('#' + plural).append(html);
        setColorHeightWidthRows(type);
    }
    function removeColorHeightWidthRow(ele)
    {
        var row = ele.parent().parent().parent().parent();
        row.remove();
        setColorHeightWidthRows(ele.data('remove-color-height-width'));
    }
    function setColorHeightWidthRows(type)
    {
        var plural = getPlural(type);

        rows[plural] = $('#' + plural).children().length;
        console.log(rows[plural])
        if(rows[plural] == 0){
            $('#' + plural + '_headers').addClass('hide');
        } else{
            $('#' + plural + '_headers').removeClass('hide');
        }

        var name = plural.replace(/(_\w)/g, function(m){return m[1].toUpperCase();});
        $('[data-' + type + ']').each(function(index){
            $(this).find('[data-color]').attr('name', name + '[' + index + '][color]');
            $(this).find('[data-height]').attr('name', name + '[' + index + '][height]');
            $(this).find('[data-width]').attr('name', name + '[' + index + '][width]');
        });
    }
    setColorHeightWidthRows('facade-lattice');
    setColorHeightWidthRows('oh-door');
    setColorHeightWidthRows('door');
    setColorHeightWidthRows('window');
    setColorHeightWidthRows('sliding-door');
    function getPlural(type){
        if(type == 'facade-lattice'){return 'facade_lattices';}
        if(type == 'oh-door'){return 'oh_doors';}
        if(type == 'door'){return 'doors';}
        if(type == 'window'){return 'windows';}
        if(type == 'sliding-door'){return 'sliding_doors';}
    }

}