$('body').on('click', '.edit-group [data-toggle-edit-group]', function(e){
    e.preventDefault();
    toggleEditGroup($(this).closest('.edit-group'))
});

function toggleEditGroup(ele)
{
    var makeActive = false;

    if (! ele.hasClass('active')){
        makeActive = true;
    }

    $('.edit-group').removeClass('active');

    if(makeActive){
        ele.addClass('active');
        if (ele.find('input[type="text"], input[type="number"], textarea').length > 0){
            var input = ele.find('input[type="text"], input[type="number"], textarea').first();
            input.focus();
            input.val(input.val());
        }
    }
}