/* Main connection exception */
$('[data-main-connection]').change(function(){
    hideOrShowMainConnectionException()
});
function hideOrShowMainConnectionException()
{
    if ($('[data-main-connection]').val() == 0){
        $('#main_connection_exception').removeClass('hide');
        $('#main_connection_exception_input').prop('disabled', false);
    } else{
        $('#main_connection_exception').addClass('hide');
        $('#main_connection_exception_input').prop('disabled', true);
    }
}
hideOrShowMainConnectionException();