$(document).foundation();

// No scrolling number
$(':input[type=number]').on('mousewheel', function(e){
    e.preventDefault();
});

//No submit with return button
$(document).on("keypress", ":not(textarea)", function(e) {
    var tag = e.target.tagName.toLowerCase();

    var element = $(e.target);
    if (e.keyCode == 13 && tag != 'textarea' && typeof element.closest('form').data('enter') == 'undefined') {
        e.preventDefault();
    }
});

$.fn.getCursorPosition = function () {
    var el = $(this).get(0);
    var pos = 0;
    if ('selectionStart' in el) {
        pos = el.selectionStart;
    } else if ('selection' in document) {
        el.focus();
        var Sel = document.selection.createRange();
        var SelLength = document.selection.createRange().text.length;
        Sel.moveStart('character', -el.value.length);
        pos = Sel.text.length - SelLength;
    }
    return pos;
};

$.fn.setCursorPosition = function(pos) {
    this.each(function(index, elem) {
        if (elem.setSelectionRange) {
            elem.setSelectionRange(pos, pos);
        } else if (elem.createTextRange) {
            var range = elem.createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
        }
    });
    return this;
};