var confirm_on;

$('[data-confirm]').click(function(e)
{
    confirmDialog($(this), e);
});

$('body').on('click', '[data-confirm]', function(e)
{
    confirmDialog($(this), e);
});

function confirmDialog(ele, e)
{
    confirm_on = ele;
    if( confirm_on.data('data-confirm') ){
        return resetDefaultBehaviour();
    }
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    var reveal = $('[data-confirm-reveal]');

    if(ele.data('confirm') == ''){
        reveal.find('[data-default]').removeClass('hide');
        reveal.find('[data-non-default]').addClass('hide');
    } else{
        var non_default_ele = reveal.find('[data-non-default]');
        reveal.find('[data-default]').addClass('hide');
        non_default_ele.removeClass('hide');
        non_default_ele.html(ele.data('confirm'))
    }

    if(ele.data('confirm-title') == ''){
        reveal.find('[data-default-title]').removeClass('hide');
        reveal.find('[data-non-default-title]').addClass('hide');
    } else{
        var non_default_ele = reveal.find('[data-non-default-title]');
        reveal.find('[data-default-title]').addClass('hide');
        non_default_ele.removeClass('hide');
        non_default_ele.html(ele.data('confirm-title'))
    }

    reveal.foundation('open');
}

$('body').on('click', '[data-confirm-yes]', function()
{
    confirm_on.data('data-confirm', true);
    confirm_on.click();
});

function resetDefaultBehaviour()
{
    if( confirm_on.is('a') && typeof confirm_on.attr('href') !== typeof undefined && confirm_on.attr('href') !== false){
        window.location.href = confirm_on.attr('href');
    }

    $('[data-confirm-reveal]').foundation('close');

    return true;
}