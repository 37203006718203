$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    complete: function(xhr, status){
        if(xhr.status == 401){
            window.location.replace("/login");
        }
    }
});

$('body').on('submit', 'form[data-remote]', function(e){
    e.preventDefault();

    var form = $(this);
    var url = form.prop('action');
    var ele = form.closest('.edit-group');
    var msg = $(this).data('remote');
    toggleEditGroup(ele);

    $.post(url, form.serialize(), function(html){
        ele.replaceWith(html);
        toast(msg)
    });
});