if ($('[data-calculation]').length > 0) {

    var ready_for_totals = false;

    function setTotals()
    {
        if (! ready_for_totals) {
            return true;
        }

        var total = 0;
        var total_m2 = 0;
        $('[data-roof-opp]').each(function(){
            total_m2 += parseFloat($(this).val());
        });
        $('[data-facade-opp]').each(function(){
            total_m2 += parseFloat($(this).val());
        });
        $('[data-wp-opp]').each(function(){
            total_m2 += parseFloat($(this).val());
        });
        $('[data-totals]').children().each(function(){
            var ele = $(this).find('[data-total-amount]');
            var elem2 = $(this).find('[data-total-amount-m2]');
            var type = ele.data('total-amount');
            var cents = 0;
            $('[data-belongs-to-price-type="' + type + '"]').each(function(){
                if(type == 12){
                     console.log($(this).data('cents'));
                }
                cents += parseInt($(this).data('cents'));
            });

            ele.html(format(cents));
            elem2.html(format(cents/total_m2));
            total += cents;
        });

        var margin = parseFloat($('[data-margin]').val().replace(',', '.')) / 100;
        margin = margin.toFixed(2);
        $('[data-margin-price]').html(format((total/total_m2)*margin));
        $('[data-total-margin-price]').html(format(Math.ceil((total*margin)/100)*100));
        var benh_margin = 0;
        var benh_price = 0;
        if($('[data-benh-margin]').length > 0){
            benh_margin = $('[data-benh-margin]').val();
            benh_margin = parseFloat(benh_margin.replace(',', '.'));
            benh_price = Math.ceil((benh_margin * total_m2))*100;
            $('[data-benh-price]').html(format(benh_price));
        }
        $('[data-sub-total]').html('<b>' + format(total) + '</b>');
        $('[data-sub-total-m2]').html('<b>' + format(total/total_m2) + '</b>');
        total += total * margin;
        total += benh_price;
        var total_m2_price = total / total_m2;
        $('[data-total-db]').val(Math.ceil(total/100)*100);
        $('[data-total-m2]').html('<b>' + format(total_m2_price.toFixed(2)) + '</b>');
        $('[data-total]').html('<b>' + format(Math.ceil(total/100)*100) + '</b>');


        setOptions();
    }

    $('[data-width], [data-length], [data-facade-type]').bind('keyup mouseup click', function(){
        var ele = $(this).closest('[data-row]');
        updateOpp(ele);
    });

    $('[data-margin], [data-benh-margin]').keyup(function(){
       setTotals();
    });

    function updateOpp(row)
    {
        var building = $('[data-building-type]').data('building-type');
        var width = row.find('[data-width]');
        var length = row.find('[data-length]');
        var opp = row.find('[data-opp]');

        width = parseFloat(width.val());
        length = parseFloat(length.val());

        var opp_value = 0;
        if(row.data('opp-type') == 'facade'){
            var facade_type = row.find('[data-facade-type]:checked').data('facade-type');
            if (facade_type == 'front'){
                if (building == 'a_shed'){
                    opp_value = (width * (length)) + ((0.5 * width) * 0.5 * (0.5 * width));
                } else{
                    opp_value = width * (length);
                }
            } else{
                opp_value = width * (length);
            }
        } else{
            width = metersToCentimeters(width);
            length = metersToCentimeters(length);
            opp_value = width * length;
        }

        opp_value = Math.ceil(opp_value);

        opp.val(opp_value);
        updatePrice(row);
    }

    $('body').on('keyup mouseup', '[data-unit], [data-unit-price]', function(){
        var ele = $(this).closest('[data-row]');
        updatePrice(ele);
    });
    /*$('[data-unit], [data-unit-price]').bind('keyup mouseup', function(){
        var ele = $(this).closest('[data-row]');
        updatePrice(ele);
    });*/

    function updatePrice(row)
    {
        var units = row.find('[data-unit]').val();
        var unitPrice = row.find('[data-unit-price]');
        var price = row.find('[data-price]');

        if(units.indexOf(',') !== -1) {
            units = correctDecimals(units);
        }
        units = String(units);
        units = parseFloat(units.replace(',', '.'));

        var cents = unitPrice.val();
        cents = correctDecimals(cents);
        cents = String(cents);
        cents = parseInt(cents.replace(',', ''));

        if(unitPrice.data('unit-price') != ''){
            cents = Math.ceil(units/unitPrice.data('unit-price')) * cents;
        } else{
            cents = units * cents;
        }

        price.data('cents', cents);

        var total = format(cents);
        price.val(total);
        setTotals();
    }

    function metersToCentimeters(meters){

        var correctedMeters = String(meters + '');
        if(correctedMeters.indexOf(',') !== -1) {
            correctedMeters = correctDecimals(correctedMeters) + '';
        }

        if(correctedMeters.indexOf(',') === -1){
            return parseFloat(correctedMeters.replace(',', '.'));
        } else{
            return parseInt(correctedMeters);
        }
    }

    function correctDecimals(cents)
    {

        if(cents.indexOf(',') === -1){
            cents = cents*100;
        } else{
            var cents_splitted = cents.split(',');
            if(cents_splitted[1].length == 1){
                cents = cents + '' + '0';
            } else if(cents_splitted[1].length == 0){
                cents = cents + '' + '00';
            }
        }

        return cents;
    }

    $('body').on('click', '[data-price-change]', function(){
        var price = $(this).data('price-change');
        var ele = $(this).closest('[data-row]');
        ele.find('[data-unit-price]').val(price);
        updatePrice(ele);
    });

    $('body').on('change', '[data-update-price-select]', function(){
        var ele = $(this).closest('[data-row]');
        var price = $('[data-update-price-select] option:selected').data('transport-price');
        ele.find('[data-unit-price]').val(price);
        updatePrice(ele);
    });

    $('[data-update-price-select]').change();

    $('[data-row]').each(function(){
        var ele = $(this);
        if(ele.data('row') == 'opp'){
            updateOpp(ele);
        } else{
            updatePrice(ele);
        }
    });

    function format(total)
    {
        total = parseFloat(total/100);

        if (total%1 == 0){
            total += ',-';
        } else{
            total = total.toFixed(2);
        }

        total = total.replace('.', ',');
        var pieces = total.split(',');
        if(pieces[0].length > 3){
            pieces[0] =  pieces[0].substr(0, pieces[0].length - 3) + '.' + pieces[0].substr(-3)
        }

        if(pieces.length == 2) {
            total = pieces[0] + ',' + pieces[1];
        } else{
            total = pieces[0];
        }

        total = '€ ' + total;

        return total;
    }



    $('[data-add-option]').click(function(){
        addOption();
    });

    function addOption()
    {
        $('[data-new-options]').append(option_row);
        calculateRows();
        setOptionsNames();
    }

    $('body').on('click', '[data-add-option-row]', function(){
        addOptionRow($(this).closest('[data-option]'));
    });

    function addOptionRow(row)
    {
        row.find('[data-new-option-rows]').append(option_row_row);
        calculateRows();
        setOptionsNames();
    }

    $('body').on('click', '[data-remove-option]', function(){
        removeOption($(this).closest('[data-option]'));
    });

    function removeOption(row){
        row.remove();
        setOptionsNames();
    }

    $('body').on('click', '[data-remove-option-row]', function(){
        removeOptionRow($(this).closest('[data-option-row]'));
    });

    function removeOptionRow(row){
        row.remove();
        setOptionsNames();
    }

    function calculateRows(){
        $('[data-option] [data-row]').each(function(){
            updatePrice($(this));
        });
    }

    function setOptionsNames()
    {
        $('[data-new-options]').children().each(function(index, value){
            $(this).find('[data-group-name]').attr('name', 'new_option[' + index + '][name]');

            $(this).find('[data-new-option-rows]').children().each(function(subIndex, subValue){
                $(this).find('[data-name]').attr('name', 'new_option[' + index + '][option_row][' + subIndex + '][name]');
                $(this).find('[data-unit]').attr('name', 'new_option[' + index + '][option_row][' + subIndex + '][amount]');
                $(this).find('[data-unit-price]').attr('name', 'new_option[' + index + '][option_row][' + subIndex + '][price_per]');
            });
        });
        $('[data-old-options]').children().each(function(index, value){
            var option_id = $(this).data('option');
            $(this).find('[data-new-option-rows]').children().each(function(subIndex, subValue){
                $(this).find('[data-name]').attr('name', 'options[' + option_id + '][new_option_row][' + subIndex + '][name]');
                $(this).find('[data-unit]').attr('name', 'options[' + option_id + '][new_option_row][' + subIndex + '][amount]');
                $(this).find('[data-unit-price]').attr('name', 'options[' + option_id + '][new_option_row][' + subIndex + '][price_per]');
            });
        });
    }

    function setOptions()
    {
        $('[data-options]').html('');
        var dataOptions = $('[data-option]');
        var margin = parseFloat($('[data-margin]').val().replace(',', '.')) / 100;
        dataOptions.each(function(){
            var ele = $(this);
            var option_description = ele.find('[data-group-name]').val();
            var price = 0;
            ele.find('[data-cents]').each(function(){
                price += parseInt($(this).data('cents'));
            });

            $('[data-options]').append('' +
                '<div class="row">' +
                    '<div class="columns medium-6 large-8">' +
                        option_description +
                    '</div>' +
                    '<div class="columns medium-3 large-2">' +
                        format(price) +
                    '</div>' +
                    '<div class="columns medium-3 large-2">' +
                        format(price * (1+margin)) +
                    '</div>' +
                '</div>' +
            '')
        });
    }
    $('body').on('keyup', '[data-group-name]', function(){
        setOptions();
    });




    $('[data-add-more-price]').click(function(){
        addMorePrice();
    });

    function addMorePrice()
    {
        $('[data-new-more-prices]').append(more_price_row);
        calculateMorePriceRows();
        setMorePricesNames();
    }

    $('body').on('click', '[data-add-more-price-row]', function(){
        addMorePriceRow($(this).closest('[data-more-price]'));
    });

    function addMorePriceRow(row)
    {
        row.find('[data-new-more-price-rows]').append(more_price_row_row);
        calculateRows();
        setMorePricesNames();
    }

    $('body').on('click', '[data-remove-more-price]', function(){
        removeMorePrice($(this).closest('[data-more-price]'));
    });

    function removeMorePrice(row){
        row.remove();
        setMorePricesNames();
    }

    $('body').on('click', '[data-remove-more-price-row]', function(){
        removeMorePriceRow($(this).closest('[data-more-price-row]'));
    });

    function removeMorePriceRow(row){
        row.remove();
        setMorePricesNames();
    }

    function calculateMorePriceRows(){
        $('[data-more-price] [data-row]').each(function(){
            updatePrice($(this));
        });
    }

    function setMorePricesNames()
    {
        $('[data-new-more-prices]').children().each(function(index, value){
            $(this).find('[data-group-more-price-name]').attr('name', 'new_more_price[' + index + '][name]');

            $(this).find('[data-new-more-price-rows]').children().each(function(subIndex, subValue){
                $(this).find('[data-name]').attr('name', 'new_more_price[' + index + '][more_price_row][' + subIndex + '][name]');
                $(this).find('[data-unit]').attr('name', 'new_more_price[' + index + '][more_price_row][' + subIndex + '][amount]');
                $(this).find('[data-unit-price]').attr('name', 'new_more_price[' + index + '][more_price_row][' + subIndex + '][price_per]');
            });
        });
        $('[data-old-more-prices]').children().each(function(index, value){
            var more_price_id = $(this).data('more-price');
            $(this).find('[data-new-more-price-rows]').children().each(function(subIndex, subValue){
                $(this).find('[data-name]').attr('name', 'more_prices[' + more_price_id + '][new_more_price_row][' + subIndex + '][name]');
                $(this).find('[data-unit]').attr('name', 'more_prices[' + more_price_id + '][new_more_price_row][' + subIndex + '][amount]');
                $(this).find('[data-unit-price]').attr('name', 'more_prices[' + more_price_id + '][new_more_price_row][' + subIndex + '][price_per]');
            });
        });
    }

    function setMorePrices()
    {
        $('[data-more-prices]').html('');
        var dataMorePrices = $('[data-more-price]');
        var margin = parseFloat($('[data-margin]').val().replace(',', '.')) / 100;
        dataMorePrices.each(function(){
            var ele = $(this);
            var more_price_description = ele.find('[data-group-more-price-name]').val();
            var price = 0;
            ele.find('[data-cents]').each(function(){
                price += parseInt($(this).data('cents'));
            });

            $('[data-more-prices]').append('' +
                '<div class="row">' +
                '<div class="columns medium-6 large-8">' +
                more_price_description +
                '</div>' +
                '<div class="columns medium-3 large-2">' +
                format(price) +
                '</div>' +
                '<div class="columns medium-3 large-2">' +
                format(price * (1+margin)) +
                '</div>' +
                '</div>' +
                '')
        });
    }
    $('body').on('keyup', '[data-group-more-price-name]', function(){
        setMorePrices();
    });


    $(document).ready(function(){
        ready_for_totals = true;
        setTotals();
    });

    if ($('[data-brinkman]').length > 0) {
        $('body').on('change', '[data-select-price-change]', function(){
            var _this = $(this);
            var price = _this.children(':checked').data('new-price');
            var ele = _this.closest('[data-row]');
            ele.find('[data-unit-price]').val(price);
            updatePrice(ele);
        });

        //WP
        function updateWpSubRows() {
            var amount = $('[data-row="solar"]').find('[data-unit]').val();

            $('[data-solar-sub-row]').each(function () {
                var ele = $(this);
                ele.find('[data-unit]').val(amount);
                updatePrice(ele.closest('[data-row]'));
            });
        }

        $('[data-wp-change], [data-wp-change]').bind('change keyup', function(){
            updateTotalWp($(this).closest('[data-row]'));
        });

        function updateTotalWp(row) {
            var wp = row.find('[data-wp-change] [data-wp]:selected').data('wp');
            var surface = row.find('[data-wp-change] [data-wp]:selected').data('surface');
            var amount = row.find('[data-unit]').val();
            var total = amount*wp;
            var total_surface = amount*surface;
            total_surface = total_surface.toFixed(2);

            $('[data-total-wp]').val(total);
            $('[data-total-surface]').val(total_surface);

            if(total_surface > parseFloat($('[data-total-roof]').val())){
                $('[data-roof-to-small]').removeClass('hide');
            } else{
                $('[data-roof-to-small]').addClass('hide');
            }
            updateWpSubRows();
        }

        if ($('[data-wp-change]').length > 0) {
            updateTotalWp($('[data-wp-change]').closest('[data-row]'));
        }
        updateWpSubRows();



        // CONVERTERS

        $('body').on('change keyup', '[data-converters], [data-max-power-change]', function(){
            updateConverterAmount($(this).closest('[data-row]'));
        });
        function updateConverterAmount(row){
            updateConveterAmounts();
            updatePrice(row);
        }
        function updateConveterAmounts()
        {
            var rows = $('[data-converter-row]');

            var max_power = 0;
            var min_power = 0;
            var amount = 0;
            rows.each(function(){
                var ele = $(this);
                var current_max_power = parseInt(ele.find('[data-max-power]:selected').data('max-power'));
                var current_min_power = parseInt(ele.find('[data-min-power]:selected').data('min-power'));
                var current_amount = parseInt(ele.find('[data-unit]').val());
                max_power += current_amount * current_max_power;
                min_power += current_amount * current_min_power;
                amount += current_amount
            });

            $('[data-total-max-power]').val(max_power);
            $('[data-total-min-power]').val(min_power);

            $('[data-converter-sub-row]').each(function(){
                $(this).find('[data-unit]').val(amount);
                updatePrice($(this));
            });
        }

        $('[data-converters]').each(function(){
            updateConverterAmount($(this).closest('[data-row]'));
        });


        $('body').on('click', '[data-remove-converter]', function(e){
            e.preventDefault();

            removeConverter($(this).closest('[data-row]'));
        });

        function removeConverter(row) {
            row.remove();

            updateConveterAmounts();
            setConverterNames();
        }

        $('[data-add-converter]').click(function(e){
            e.preventDefault();
            addConverter();
        });
        function addConverter(){
            $('[data-new-converters]').append(converter_row);
            setConverterNames();

            var row = $('[data-new-converters]').children().last();
            updatePrice(row);
            updateConverterAmount(row)
        }

        function setConverterNames()
        {
            $('[data-new-converters]').children().each(function(index, value){
                $(this).find('[data-converters]').attr('name', 'new_converter[' + index + '][converter_id]');
                $(this).find('[data-unit]').attr('name', 'new_converter[' + index + '][amount]');
                $(this).find('[data-unit-price]').attr('name', 'new_converter[' + index + '][price_per_unit]');
            });
        }


        // OPTIMIZERS
        $('body').on('click', '[data-remove-optimizer]', function(e){
            e.preventDefault();

            removeOptimizer($(this).closest('[data-row]'));
        });

        function removeOptimizer(row) {
            row.remove();

            setOptimizerNames();
        }

        $('[data-add-optimizer]').click(function(e){
            e.preventDefault();
            addOptimizer();
        });
        function addOptimizer(){
            $('[data-new-optimizers]').append(optimizer_row);

            setOptimizerNames();

            var row = $('[data-new-optimizers]').children().last();
            updatePrice(row);
        }


        function setOptimizerNames()
        {
            $('[data-new-optimizers]').children().each(function(index, value){
                $(this).find('[data-optimizers]').attr('name', 'new_optimizer[' + index + '][optimizer_id]');
                $(this).find('[data-unit]').attr('name', 'new_optimizer[' + index + '][amount]');
                $(this).find('[data-unit-price]').attr('name', 'new_optimizer[' + index + '][price_per_unit]');
            });
        }

    }
}