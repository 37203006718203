if($('table[data-paginate]').length > 0){

    var element = $('table[data-paginate]');
    var url = element.data('paginate');
    var take = element.data('take');
    var skip = 0;
    var total = 0;
    var max_pages = 0;
    var current_page = element.data('current-page');
    var doneTypingInterval = 400;
    var typingTimer;
    var search = $('[data-paginate-search]').val();
    var filters = $('[data-filter]');

    var prev_search = $('[data-paginate-search]').val();
    var reload = true;

    filters.change(function(){
        paginate();
    });

    $('[data-paginate-pages] a').click(function(){
        if(current_page != $(this).data('open-page')){
            current_page = $(this).data('open-page');

            paginate();
        }
    });

    $('[data-paginate-next]').click(function() {
        if(current_page != max_pages){
            current_page = current_page + 1;

            paginate();
        }
    });

    $('[data-paginate-first]').click(function() {
        if(current_page != 1){
            current_page = 1;

            paginate();
        }
    });

    $('[data-paginate-last]').click(function() {
        if(current_page != max_pages){
            current_page = max_pages;

            paginate();
        }
    });

    $('[data-paginate-prev]').click(function() {
        if(current_page != 1){
            current_page = current_page - 1;
            paginate();
        }
    });

    $('[data-paginate-search]').keyup(function(){
        search = $(this).val();
        clearTimeout(typingTimer);
        typingTimer = setTimeout(paginate, doneTypingInterval);
    });

    function paginate()
    {
        if(prev_search != search) {
            current_page = 1;
        }

        skip = (current_page - 1) * take;
        prev_search = search;
        var data = {
            take: take,
            skip: skip,
            search: search,
            current_page: current_page
        };

        filters.each(function(){
            data[$(this).data('filter')] = $(this).val();
        });

        $.post(url, data, function(result){
            total = result['filtered_count'];
            element.find('tbody').html(result['html']);

            max_pages = Math.ceil(total / take);
            var start_page = current_page-4;

            if(start_page < 1) {
                start_page = 1;
            }

            if(start_page >= 9 && start_page+8 > max_pages)
            {
                start_page = max_pages-8;
            }

            $('[data-paginate-pages] a').each(function(index, ele) {
                var page = (start_page+index);

                if(page > (max_pages)) {
                    $(this).hide()
                } else{
                    $(this).show()
                }

                $(this).data('open-page', page);
                $(this).html(page);

                if(page == current_page){
                    $(this).addClass('active');
                } else{
                    $(this).removeClass('active');
                }
            });

        });
    }

    paginate();
}

if($('table[data-paginate-small]').length > 0){

    var paginate_page = 1;
    var paginate_amount = 10;
    var paginate_max = Math.ceil($('table[data-paginate-small] tbody tr').length / paginate_amount);

    $('[data-paginate-next]').click(function(){
        paginate_page = paginate_page+1;

        if(paginate_page > paginate_max){
            paginate_page = paginate_max;
        }

        setPagination();
    });

    $('[data-paginate-prev]').click(function(){
        paginate_page = paginate_page-1;

        if(paginate_page < 1){
            paginate_page = 1;
        }

        setPagination();
    });

    function setPagination()
    {
        var start = (paginate_page - 1) * paginate_amount;
        var end = (paginate_page * paginate_amount) - 1;

        $('table[data-paginate-small] tbody tr').each(function(index, ele){
            if(index >= start && index <= end){
                $(this).show();
            } else{
                $(this).hide();
            }
        });
    }

    setPagination();
}
